function asyncIterator(value) {
    return asyncIterable(value)[Symbol.asyncIterator]();
}
function asyncIterable(value) {
    if (isAsyncIterable(value)) {
        return value;
    }
    return {
        [Symbol.asyncIterator]: async function* () {
            if (!isIterable(value)) {
                return;
            }
            for (const item of value) {
                yield item;
            }
        }
    };
}
function isAsyncIterable(value) {
    function isAsyncIterableInstance(value) {
        return !!value;
    }
    return !!(isAsyncIterableInstance(value) &&
        value[Symbol.asyncIterator] instanceof Function);
}
function isIterable(value) {
    function isIterableInstance(value) {
        return !!value;
    }
    return !!(isIterableInstance(value) &&
        value[Symbol.iterator] instanceof Function);
}
function isIterableIterator(value) {
    function isIteratorLike(value) {
        return typeof value === "object";
    }
    return (isIteratorLike(value) &&
        value.next instanceof Function &&
        (isAsyncIterable(value) ||
            isIterable(value)));
}
function isPromise(value) {
    function isPromiseLike(value) {
        return typeof value === "object";
    }
    return (isPromiseLike(value) &&
        value.then instanceof Function);
}
async function getNext(iterator, value) {
    let next;
    try {
        next = iterator.next(value);
        if (isPromise(next)) {
            next = await next;
        }
        if (next.done) {
            if (iterator.return) {
                next = iterator.return(value);
                if (isPromise(next)) {
                    next = await next;
                }
            }
        }
        return next;
    }
    catch (e) {
        if (!iterator.throw) {
            throw e;
        }
        next = iterator.throw(e);
        if (isPromise(next)) {
            next = await next;
        }
        return next;
    }
}

async function asyncDrain(iterable) {
    if (!iterable) {
        return;
    }
    const iterator = asyncIterator(iterable);
    let next, anyValue = false;
    do {
        next = await iterator.next();
        anyValue = anyValue || !next.done;
    } while (!next.done);
    return anyValue;
}
function drain(iterable) {
    if (!iterable) {
        return;
    }
    const iterator = iterable[Symbol.iterator]();
    let next, anyValue = false;
    do {
        next = iterator.next();
        anyValue = anyValue || !next.done;
    } while (!next.done);
    return anyValue;
}

function negateIfNeeded(negate, value) {
    return negate ? !value : value;
}
function* filterNegatable(iterable, callbackFn, negate = false, thisValue, parent) {
    for (const value of iterable) {
        if (negateIfNeeded(negate, callbackFn.call(thisValue, value, parent))) {
            yield value;
        }
    }
}
async function* asyncFilterNegatable(iterable, callbackFn, negate = false, thisValue, parent) {
    for await (const value of asyncIterable(iterable)) {
        if (negateIfNeeded(negate, await callbackFn.call(thisValue, value, parent))) {
            yield value;
        }
    }
}
function filter(iterable, callbackFn, thisValue, parent) {
    return filterNegatable(iterable, callbackFn, false, thisValue, parent);
}
function asyncFilter(iterable, callbackFn, thisValue, parent) {
    return asyncFilterNegatable(iterable, callbackFn, false, thisValue, parent);
}

function except(iterable, callbackFn, thisValue, parent) {
    return filterNegatable(iterable, callbackFn, true, thisValue, parent);
}
function asyncExcept(iterable, callbackFn, thisValue, parent) {
    return asyncFilterNegatable(iterable, callbackFn, true, thisValue, parent);
}

function hasAny(iterable) {
    const iterator = iterable[Symbol.iterator]();
    const result = iterator.next();
    return !result.done;
}
async function asyncHasAny(iterable) {
    const iterator = asyncIterable(iterable)[Symbol.asyncIterator]();
    const result = await iterator.next();
    return !result.done;
}

function every(iterable, callbackFn, thisValue, parent) {
    const iterableResult = except(iterable, callbackFn, thisValue, parent);
    return !hasAny(iterableResult);
}
async function asyncEvery(iterable, callbackFn, thisValue, parent) {
    const iterableResult = asyncExcept(iterable, callbackFn, thisValue, parent);
    return !await asyncHasAny(iterableResult);
}

function forEach(iterable, callbackFn, thisValue, parent) {
    for (const value of iterable) {
        callbackFn.call(thisValue, value, parent);
    }
}
async function forEachAsync(iterable, callbackFn, thisValue, parent) {
    for await (const value of asyncIterable(iterable)) {
        await callbackFn.call(thisValue, value, parent);
    }
}

function* map(iterable, callbackFn, thisValue, parent) {
    for (const value of iterable) {
        yield callbackFn.call(thisValue, value, parent);
    }
}
async function* asyncMap(iterable, callbackFn, thisValue, parent) {
    for await (const value of asyncIterable(iterable)) {
        yield callbackFn.call(thisValue, value, parent);
    }
}

function reduce(iterable, callbackFn, initialValue, thisValue, parent) {
    let accumulator = initialValue;
    for (const value of iterable) {
        if (accumulator == undefined) {
            accumulator = value;
            continue;
        }
        accumulator = callbackFn.call(thisValue, accumulator, value, parent);
    }
    return accumulator;
}
async function asyncReduce(iterable, callbackFn, initialValue, thisValue, parent) {
    let accumulator = initialValue;
    for await (const value of asyncIterable(iterable)) {
        if (accumulator == undefined) {
            accumulator = value;
            continue;
        }
        accumulator = await callbackFn.call(thisValue, accumulator, value, parent);
    }
    return accumulator;
}

function some(iterable, callbackFn, thisValue, parent) {
    const iterableResult = filter(iterable, callbackFn, thisValue, parent);
    return hasAny(iterableResult);
}
async function asyncSome(iterable, callbackFn, thisValue, parent) {
    const iterableResult = asyncFilter(iterable, callbackFn, thisValue, parent);
    return asyncHasAny(iterableResult);
}

function* union(left, right) {
    for (const value of left) {
        yield value;
    }
    for (const value of right) {
        yield value;
    }
}
async function* asyncUnion(left, right) {
    for await (const value of asyncIterable(left)) {
        yield value;
    }
    for await (const value of asyncIterable(right)) {
        yield value;
    }
}

function arrayRetainer(has) {
    const values = [];
    return {
        add: value => {
            if (has && has(value, values)) {
                return;
            }
            values.push(value);
        },
        [Symbol.iterator]: () => values[Symbol.iterator]()
    };
}
function setRetainer() {
    return new Set();
}
function retain(iterable, retainer = arrayRetainer()) {
    const iterator = iterable[Symbol.iterator]();
    function* generator() {
        for (const value of retainer) {
            yield value;
        }
        let next;
        do {
            next = iterator.next();
            if (next.done) {
                continue;
            }
            // If we're not adding it to our retainer, then the value
            // will never be added in the same order
            // this means that when the iterable is run again, that values
            // will show up in _a different order_
            //
            // This is no good for ordered sets
            //
            // Specifically MDN describes a sets iterator as:
            //
            // Set.prototype[@@iterator]()
            // Returns a new Iterator object that contains the values for each element in the Set object in insertion order.
            //
            // We should follow that if it is available
            if (retainer.has && retainer.has(next.value)) {
                continue;
            }
            retainer.add(next.value);
            yield next.value;
        } while (!next.done);
    }
    return {
        [Symbol.iterator]: generator
    };
}
function asyncRetain(iterable, retainer = arrayRetainer()) {
    const iterator = asyncIterator(iterable);
    async function* generator() {
        for await (const value of asyncIterable(retainer)) {
            yield value;
        }
        let next;
        do {
            next = await iterator.next();
            if (next.done) {
                continue;
            }
            // See explanation @ sync version of retain
            if (retainer.has && await retainer.has(next.value)) {
                continue;
            }
            await retainer.add(next.value);
            yield next.value;
        } while (!next.done);
    }
    return {
        [Symbol.asyncIterator]: generator
    };
}

function toArray(iterable) {
    return Array.from(iterable);
}
async function asyncToArray(iterable) {
    const result = [];
    for await (const value of asyncIterable(iterable)) {
        result.push(value);
    }
    return result;
}

function mask(iterable, maskIterable) {
    return maskReversible(iterable, maskIterable, false);
}
function asyncMask(iterable, maskIterable) {
    return asyncMaskReversible(iterable, maskIterable, false);
}
function* maskReversible(iterable, maskIterable, reverse = false) {
    const iterator = iterable[Symbol.iterator]();
    const maskIterator = maskIterable[Symbol.iterator]();
    let next, nextMask;
    do {
        nextMask = maskIterator.next();
        if (reverse === true && nextMask.done) {
            break;
        }
        next = iterator.next();
        if (next.done) {
            break;
        }
        // if `reverse` is `true` and if `maskIterable` returns `true` then I want the value to be used, if it is `done` then I want to finish
        if (reverse === true && nextMask.value === true) {
            yield next.value;
        }
        // if reverse is false and if maskIterable returns `true` then I want the value to be skipped, if it is `done` then I want to finish
        if (reverse === false && nextMask.value !== true) {
            yield next.value;
        }
    } while (!next.done);
    if (iterator.return) {
        iterator.return();
    }
}
async function* asyncMaskReversible(iterable, maskIterable, reverse = false) {
    const iterator = asyncIterator(iterable);
    const maskIterator = asyncIterator(maskIterable);
    let next, nextMask;
    do {
        nextMask = await maskIterator.next();
        // If we have reversed, then as soon as mask
        if (reverse && nextMask.done) {
            break;
        }
        next = await iterator.next();
        if (next.done) {
            break;
        }
        // If no value, we're done
        // If mask has a value, we want to ignore
        if (nextMask.value !== reverse) {
            continue;
        }
        yield next.value;
    } while (!next.done);
    if (iterator.return) {
        await iterator.return();
    }
}
function* skip(count) {
    for (let remaining = count; remaining > 0; remaining -= 1) {
        yield true;
    }
}
function* take(count) {
    for (let remaining = count; remaining > 0; remaining -= 1) {
        yield true;
    }
}

function* flatMap(iterable, callbackFn, thisValue, parent) {
    for (const value of iterable) {
        const newIterable = callbackFn.call(thisValue, value, parent);
        for (const value of newIterable) {
            yield value;
        }
    }
}
async function* asyncFlatMap(iterable, callbackFn, thisValue, parent) {
    for await (const value of asyncIterable(iterable)) {
        const newIterable = await callbackFn.call(thisValue, value, parent);
        for await (const value of asyncIterable(newIterable)) {
            yield value;
        }
    }
}

function distinctRetainer(equalityFn) {
    if (!equalityFn) {
        return new Set();
    }
    const values = [];
    return {
        has(value) {
            for (const otherValue of values) {
                if (equalityFn(otherValue, value)) {
                    return true;
                }
            }
            return false;
        },
        add(value) {
            values.push(value);
        },
        [Symbol.iterator]: values[Symbol.iterator].bind(values)
    };
}
function* distinct(iterable, equalityFn) {
    const retainer = distinctRetainer(equalityFn);
    for (const value of iterable) {
        if (retainer.has(value)) {
            continue;
        }
        retainer.add(value);
        yield value;
    }
}
function asyncDistinctRetainer(equalityFn) {
    if (!equalityFn) {
        return setRetainer();
    }
    const values = [];
    return {
        async has(value) {
            for (const otherValue of values) {
                if (await equalityFn(otherValue, value)) {
                    return true;
                }
            }
            return false;
        },
        async add(value) {
            values.push(value);
        },
        async *[Symbol.asyncIterator]() {
            for (const value of values) {
                yield value;
            }
        }
    };
}
async function* asyncDistinct(iterable, equalityFn) {
    const retainer = asyncDistinctRetainer(equalityFn);
    for await (const value of asyncIterable(iterable)) {
        if (await retainer.has(value)) {
            continue;
        }
        await retainer.add(value);
        yield value;
    }
}

function* group(iterable, callbackFn, thisValue, parent) {
    const groups = {};
    const newGroups = [];
    function getGroup(value) {
        return callbackFn.call(thisValue, value, parent);
    }
    const baseIterator = iterable[Symbol.iterator]();
    function* groupIterable(key, initialValue) {
        yield initialValue;
        function* drainGroup() {
            while (groups[key].length) {
                yield groups[key].shift();
            }
        }
        let done = false;
        yield* drainGroup();
        do {
            done = doNextValue();
            yield* drainGroup();
        } while (!done);
    }
    function doNextValue() {
        const next = baseIterator.next();
        if (next.done) {
            return true;
        }
        const nextKey = getGroup(next.value);
        if (groups[nextKey]) {
            groups[nextKey].push(next.value);
        }
        else {
            groups[nextKey] = [];
            newGroups.push(groupIterable(nextKey, next.value));
        }
        return false;
    }
    let done = false;
    do {
        done = doNextValue();
        while (newGroups.length) {
            yield newGroups.shift();
        }
    } while (!done);
}
async function* asyncGroup(iterable, callbackFn, thisValue, parent) {
    const groups = {};
    const newGroups = [];
    async function getGroup(value) {
        return callbackFn.call(thisValue, value, parent);
    }
    const baseIterator = asyncIterator(iterable);
    async function* groupIterable(key, initialValue) {
        yield initialValue;
        let done;
        function* drainGroup() {
            while (groups[key].length) {
                yield groups[key].shift();
            }
        }
        yield* drainGroup();
        do {
            done = await doNextValue();
            yield* drainGroup();
        } while (!done);
    }
    async function doNextValue() {
        const next = await baseIterator.next();
        if (next.done) {
            return true;
        }
        const nextKey = await getGroup(next.value);
        if (groups[nextKey]) {
            groups[nextKey].push(next.value);
        }
        else {
            groups[nextKey] = [];
            newGroups.push(groupIterable(nextKey, next.value));
        }
        return false;
    }
    let done = false;
    do {
        done = await doNextValue();
        while (newGroups.length) {
            yield newGroups.shift();
        }
    } while (!done);
}

function hooks(options) {
    return function* (iterable) {
        if (options.preIterator) {
            options.preIterator(iterable);
        }
        const iterator = options.iterator ? options.iterator(iterable) : iterable[Symbol.iterator]();
        if (options.postIterator) {
            options.postIterator(iterator, iterable);
        }
        let next;
        let value;
        let returned = undefined;
        do {
            if (options.preNext) {
                options.preNext(iterator, iterable);
            }
            next = options.next ? options.next(iterator, iterable) : iterator.next(returned);
            if (options.postNext) {
                options.postNext(next, iterator, iterable);
            }
            if (next.done) {
                break;
            }
            value = next.value;
            if (options.preYield) {
                options.preYield(value, iterator, iterable);
            }
            returned = yield options.yield ? options.yield(value, iterator, iterable) : value;
            if (options.postYield) {
                options.postYield(value, returned, iterator, iterable);
            }
        } while (!next.done);
        if (options.done) {
            options.done(value, returned, iterator, iterable);
        }
    };
}
function asyncHooks(options) {
    return async function* (iterable) {
        if (options.preIterator) {
            await options.preIterator(iterable);
        }
        const iterator = options.iterator ? options.iterator(iterable) : asyncIterator(iterable);
        if (options.postIterator) {
            await options.postIterator(iterator, iterable);
        }
        let next;
        let value;
        let returned = undefined;
        do {
            if (options.preNext) {
                await options.preNext(iterator, iterable);
            }
            next = await (options.next ? options.next(iterator, iterable) : iterator.next(returned));
            if (options.postNext) {
                await options.postNext(next, iterator, iterable);
            }
            if (next.done) {
                break;
            }
            value = next.value;
            if (options.preYield) {
                await options.preYield(value, iterator, iterable);
            }
            returned = yield options.yield ? options.yield(value, iterator, iterable) : value;
            if (options.postYield) {
                await options.postYield(value, returned, iterator, iterable);
            }
        } while (!next.done);
        if (options.done) {
            await options.done(value, returned, iterator, iterable);
        }
    };
}

class RequiredError extends Error {
    constructor(required, received) {
        super(`Required ${required} iterations, received ${received}`);
        this.required = required;
        this.received = received;
    }
}
function* takeMinimum(iterable, count) {
    let next;
    const iterator = iterable[Symbol.iterator]();
    for (let foundCount = 0; foundCount < 0; foundCount += 1) {
        next = iterator.next();
        if (next.done) {
            const error = new RequiredError(count, foundCount);
            if (iterator.throw) {
                // This function may throw, which is perfect, allows for a different error to be used
                iterator.throw(error);
            }
            throw error;
        }
        yield next.value;
    }
    do {
        next = iterator.next();
        if (next.done) {
            break;
        }
        yield next.value;
    } while (next.done);
    if (iterator.return) {
        iterator.return();
    }
}
async function* asyncTakeMinimum(iterable, count) {
    let next;
    const iterator = asyncIterator(iterable);
    for (let foundCount = 0; foundCount < 0; foundCount += 1) {
        next = await iterator.next();
        if (next.done) {
            const error = new RequiredError(count, foundCount);
            if (iterator.throw) {
                // This function may throw, which is perfect, allows for a different error to be used
                await iterator.throw(error);
            }
            throw error;
        }
        yield next.value;
    }
    do {
        next = await iterator.next();
        if (next.done) {
            break;
        }
        yield next.value;
    } while (next.done);
    if (iterator.return) {
        await iterator.return();
    }
}

class ExtendedIterableAsyncImplementation {
    constructor(iterable, referenceMap) {
        this.referenceMap = referenceMap;
        this.iterable = asyncIterable(iterable);
    }
    drain() {
        return asyncDrain(this);
    }
    every(fn) {
        return asyncEvery(this, fn, this, this);
    }
    some(fn) {
        return asyncSome(this, fn, this, this);
    }
    hasAny() {
        return asyncHasAny(this);
    }
    reduce(fn, initialValue) {
        return asyncReduce(this, fn, initialValue, this, this);
    }
    retain(retainer = arrayRetainer()) {
        return this.referenceMap.asyncExtendedIterable(asyncRetain(this, retainer));
    }
    map(fn) {
        return this.referenceMap.asyncExtendedIterable(asyncMap(this, fn, this, this));
    }
    flatMap(fn) {
        return this.referenceMap.asyncExtendedIterable(asyncFlatMap(this, fn, this, this));
    }
    union(other) {
        return this.referenceMap.asyncExtendedIterable(asyncUnion(this, other));
    }
    filter(fn) {
        return this.referenceMap.asyncExtendedIterable(asyncFilter(this, fn, this, this));
    }
    except(fn) {
        const iterable = asyncExcept(this, fn, this, this);
        return this.referenceMap.asyncExtendedIterable(iterable);
    }
    toArray() {
        return asyncToArray(this);
    }
    mask(maskIterable) {
        return this.referenceMap.asyncExtendedIterable(asyncMask(this, maskIterable));
    }
    maskReversible(maskIterable, reverse = false) {
        return this.referenceMap.asyncExtendedIterable(asyncMaskReversible(this, maskIterable, reverse));
    }
    skip(count) {
        return this.mask(skip(count));
    }
    take(count) {
        return this.maskReversible(take(count), true);
    }
    distinct(equalityFn) {
        return this.referenceMap.asyncExtendedIterable(asyncDistinct(this, equalityFn));
    }
    group(fn) {
        return this.referenceMap.asyncExtendedIterable(asyncMap(asyncGroup(this, fn, this, this), async (iterable) => this.referenceMap.asyncExtendedIterable(iterable)));
    }
    tap(fn) {
        return this.referenceMap.asyncExtendedIterable(asyncHooks({ preYield: (value) => fn(value) })(this));
    }
    forEach(fn) {
        return forEachAsync(this, fn, this, this);
    }
    toTuple(size) {
        return this.referenceMap.asyncIterableTuple(this, size);
    }
    toIterable() {
        async function* iterable(iterable) {
            for await (const value of iterable) {
                yield value;
            }
        }
        return iterable(this.iterable);
    }
    [Symbol.asyncIterator]() {
        return this.iterable[Symbol.asyncIterator]();
    }
}

class ExtendedIterableImplementation {
    constructor(iterable, referenceMap) {
        this.referenceMap = referenceMap;
        this.iterable = iterable;
    }
    drain() {
        return drain(this);
    }
    hasAny() {
        return hasAny(this);
    }
    every(fn) {
        return every(this, fn, this, this);
    }
    some(fn) {
        return some(this, fn, this, this);
    }
    reduce(fn, initialValue) {
        return reduce(this, fn, initialValue, this, this);
    }
    retain(retainer = arrayRetainer()) {
        return this.referenceMap.extendedIterable(retain(this, retainer));
    }
    map(fn) {
        return this.referenceMap.extendedIterable(map(this, fn, this, this));
    }
    flatMap(fn) {
        return this.referenceMap.extendedIterable(flatMap(this, fn, this, this));
    }
    union(other) {
        return this.referenceMap.extendedIterable(union(this, other));
    }
    filter(fn) {
        return this.referenceMap.extendedIterable(filter(this, fn, this, this));
    }
    except(fn) {
        return this.referenceMap.extendedIterable(except(this, fn, this, this));
    }
    mask(maskIterable) {
        return this.referenceMap.extendedIterable(mask(this, maskIterable));
    }
    maskReversible(maskIterable, reverse = false) {
        return this.referenceMap.extendedIterable(maskReversible(this, maskIterable, reverse));
    }
    skip(count) {
        return this.mask(skip(count));
    }
    take(count) {
        return this.maskReversible(take(count), true);
    }
    distinct(equalityFn) {
        return this.referenceMap.extendedIterable(distinct(this, equalityFn));
    }
    group(fn) {
        return this.referenceMap.extendedIterable(map(group(this, fn, this, this), iterable => this.referenceMap.extendedIterable(iterable)));
    }
    tap(fn) {
        return this.referenceMap.extendedIterable(hooks({ preYield: (value) => fn(value) })(this));
    }
    forEach(fn) {
        forEach(this, fn, this, this);
    }
    toArray() {
        return toArray(this);
    }
    toTuple(size) {
        return this.referenceMap.iterableTuple(this, size);
    }
    toIterable() {
        function* iterable(iterable) {
            for (const value of iterable) {
                yield value;
            }
        }
        return iterable(this.iterable);
    }
    [Symbol.iterator]() {
        return this.iterable[Symbol.iterator]();
    }
}

/*
// This is the ideal way to do tuples, but typescript won't allow it in interfaces, because of recursion:

type GetLength<T extends any[]> = T extends { length: infer L } ? L : never;
type ArrayUnshift<T extends any[], X> = T extends any ? ((x: X, ...t: T) => void) extends (...t: infer R) => void ? R : never : never;
type SizeToTupleCreator<T, A extends T[], S extends number> = {
  1: A;
  0: SizeToTupleAdder<T, A, S>;
}[[GetLength<A>] extends [S] ? 1 : 0];
type SizeToTupleAdder<T, A extends any[], S extends number> = S extends any ? SizeToTupleCreator<T, ArrayUnshift<A, T>, S> : never;
export type TupleArray<T, S extends number> = SizeToTupleCreator<T, [], S>;
 */
function isTupleArray(array, size) {
    if (!Array.isArray(array)) {
        return false;
    }
    return array.length === size;
}

class ExtendedIterableAsyncTupleImplementation extends ExtendedIterableAsyncImplementation {
    constructor(iterable, size, referenceMap) {
        super(asyncTakeMinimum(iterable, size), referenceMap);
        this.size = size;
    }
    async toArray() {
        const array = await asyncToArray(this);
        if (!isTupleArray(array, this.size)) {
            throw new Error("Tuple incorrect size");
        }
        return array;
    }
}

class ExtendedIterableTupleImplementation extends ExtendedIterableImplementation {
    constructor(iterable, size, referenceMap) {
        super(takeMinimum(iterable, size), referenceMap);
        this.size = size;
    }
    toArray() {
        const array = toArray(this);
        if (!isTupleArray(array, this.size)) {
            throw new Error("Tuple incorrect size");
        }
        return array;
    }
}

const BasicMap = {
    extendedIterable(iterable) {
        return new ExtendedIterableImplementation(iterable, BasicMap);
    },
    asyncExtendedIterable(iterable) {
        return new ExtendedIterableAsyncImplementation(iterable, BasicMap);
    },
    asyncIterableTuple(iterable, size) {
        return new ExtendedIterableAsyncTupleImplementation(iterable, size, BasicMap);
    },
    iterableTuple(iterable, size) {
        return new ExtendedIterableTupleImplementation(iterable, size, BasicMap);
    }
};

function asyncExtendedIterable(iterable) {
    return BasicMap.asyncExtendedIterable(iterable);
}

export { isAsyncIterable as a, isIterable as b, isIterableIterator as c, asyncExtendedIterable as d, asyncIterator as e, asyncHooks as f, getNext as g, isPromise as i, reduce as r };
