import {render, DOMVContext} from "./_snowpack/pkg/@virtualstate/dom.js";
import {SiteBody} from "./site.js";
import {h} from "./h.js";
async function run() {
  const root = document.getElementById("root");
  if (!root) {
    throw new Error("Expected root");
  }
  const context = new DOMVContext({
    root
  });
  await render(/* @__PURE__ */ h(SiteBody, null), context);
  console.log("Completed rendering");
  await context.close();
}
async function reportTimeline(timeline) {
}
window.proposalSiteRender = run();
window.proposalSiteRender.catch((error) => {
  throw error;
});
