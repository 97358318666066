import {h} from "../h.js";
import {createFragment} from "../_snowpack/pkg/@virtualstate/x.js";
import {Goals} from "./goals.js";
import {Template} from "../template.js";
export const Intro = /* @__PURE__ */ h(createFragment, null, /* @__PURE__ */ h(Template, {
  id: "intro-template"
}, /* @__PURE__ */ h("h1", null, "Virtual State ", /* @__PURE__ */ h("span", {
  class: "branded-x"
}, "X")), /* @__PURE__ */ h("div", {
  class: "quote"
}, "Psst... see the ", /* @__PURE__ */ h("a", {
  href: "https://github.com/virtualstate/x",
  target: "_blank",
  rel: "noopener"
}, "GitHub repository for this project here"), " or alternatively ", /* @__PURE__ */ h("a", {
  href: "https://www.npmjs.com/package/@virtualstate/x",
  target: "_blank",
  rel: "noopener"
}, "the npm package here"))), Goals);
