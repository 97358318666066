import {h} from "../h.js";
import {Intro} from "./intro.js";
import {Example} from "./example.js";
import {Tokens} from "./tokens.js";
import {Template} from "../template.js";
export const SiteContents = /* @__PURE__ */ h("main", null, Intro, Example, Tokens, /* @__PURE__ */ h(Template, {
  id: "main-footer"
}, /* @__PURE__ */ h("footer", null, /* @__PURE__ */ h("h4", null, "Licence"), /* @__PURE__ */ h("p", null, "This website and ", /* @__PURE__ */ h("a", {
  href: "https://github.com/virtualstate/virtualstate.dev",
  target: "_blank",
  rel: "noopener noreferrer"
}, "associated GitHub respository"), " is licensed under the ", /* @__PURE__ */ h("a", {
  href: "https://creativecommons.org/publicdomain/zero/1.0/",
  target: "_blank",
  rel: "noopener noreferrer"
}, "CC0 1.0 Universal"), " license."))));
