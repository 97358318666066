import {h} from "../h.js";
import {createToken} from "../_snowpack/pkg/@virtualstate/x.js";
import {Template} from "../template.js";
const Select = createToken(Symbol("Select"), {
  defaultValue: "",
  class: "select"
}, /* @__PURE__ */ h("option", {
  disabled: true
}, "No options available"));
const PersonUpdateSymbol = Symbol();
const PersonUpdate = createToken(PersonUpdateSymbol);
const PersonUpdateFormSymbol = Symbol();
const PersonUpdateForm = createToken(PersonUpdateFormSymbol);
export const Tokens = /* @__PURE__ */ h(Template, {
  id: "tokens-template"
}, /* @__PURE__ */ h("section", {
  id: "tokens"
}, /* @__PURE__ */ h("h2", null, "Tokens"), /* @__PURE__ */ h("p", null, "This pattern introduces a concept called ", /* @__PURE__ */ h("em", null, "tokens"), ". A token represents some functionality that has not yet been implemented, allowing for an external consumer to replace the implementation of the token. A good example of this pattern would be a select input, where we may have a default disabled option, or provide an option"), /* @__PURE__ */ h("pre", null, `
const Select = createToken(
    Symbol("Select"),
    {
       defaultValue: "",
       class: "select"
    },
    // Default children
    <option disabled>No options available</option>
);

// Would render 
// <Select defaultValue="" class="select">{defaultChildren}</Select>
const DefaultSelect = <Select />;

// Would render 
// <Select defaultValue="first" class="select">{givenChildren}</Select>
const MySelect = (
  <Select defaultValue="first">
    <option value="first">First</option>
    <option value="second">Second</option>
  </Select>
);
      `.trim()), /* @__PURE__ */ h("div", {
  hidden: true
}, /* @__PURE__ */ h(Select, null), /* @__PURE__ */ h(Select, {
  defaultValue: "first"
}, /* @__PURE__ */ h("option", {
  value: "first"
}, "First"), /* @__PURE__ */ h("option", {
  value: "second"
}, "Second"))), /* @__PURE__ */ h("p", null, "These examples are best seen with UI components, but tokens have more use in generic processes where the tokens are used defined data points"), /* @__PURE__ */ h("pre", null, `

interface PersonUpdateOptions {
  firstName: string;
  lastName: string;
}
const PersonUpdateSymbol = Symbol()
const PersonUpdate = createToken<typeof PersonUpdateSymbol, PersonUpdateOptions>(PersonUpdateSymbol);

interface PersonUpdateFormOptions {
  onPersonUpdate(update: PersonUpdateOptions): void
}
const PersonUpdateFormSymbol = Symbol()
const PersonUpdateForm = createToken<typeof PersonUpdateFormSymbol, PersonUpdateFormOptions>(PersonUpdateFormSymbol);

async function *Component() {
  const { resolve, promise } = defer<PersonUpdateOptions>();
  yield <PersonUpdateForm onPersonUpdate={resolve} />
  const { firstName, lastName } = await promise;
  yield <PersonUpdate firstName={firstName} lastName={lastName} />
}

      `.trim()), /* @__PURE__ */ h("div", {
  hidden: true
}, /* @__PURE__ */ h(PersonUpdateForm, {
  onPersonUpdate: noop
}), /* @__PURE__ */ h(PersonUpdate, {
  firstName: "First Name!",
  lastName: "Last Name!"
}))));
function noop() {
  return void 0;
}
