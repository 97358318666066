import {createNode, Fragment} from "./_snowpack/pkg/@virtualstate/x.js";
import {
  isGetDocumentNodeOptions,
  isNativeOptions,
  isOnBeforeRenderOptions,
  isAttributesOptions
} from "./_snowpack/pkg/@virtualstate/dom.js";
export function h(source, options, ...children) {
  if (source === "fragment") {
    return h(Fragment, options, ...children);
  }
  if (typeof source === "string" && options && !isNativeOptions(options)) {
    const resultingOptions = {
      type: "Element",
      attributes: {}
    };
    const toJSON = () => ({
      attributes: resultingOptions.attributes
    });
    Object.defineProperty(resultingOptions, "toJSON", {
      value: toJSON,
      enumerable: false
    });
    let remainingOptions = options;
    if (isGetDocumentNodeOptions(remainingOptions)) {
      const {getDocumentNode, ...nextRemainingOptions} = remainingOptions;
      remainingOptions = nextRemainingOptions;
      resultingOptions.getDocumentNode = getDocumentNode;
    }
    if (isOnBeforeRenderOptions(remainingOptions)) {
      const {onBeforeRender, ...nextRemainingOptions} = remainingOptions;
      remainingOptions = nextRemainingOptions;
      resultingOptions.onBeforeRender = onBeforeRender;
    }
    const finalOptions = {
      attributes: remainingOptions
    };
    if (isAttributesOptions(finalOptions)) {
      resultingOptions.attributes = finalOptions.attributes;
    }
    return h(source, resultingOptions, ...children);
  }
  return createNode(source, options || {}, ...children);
}
