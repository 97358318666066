import {isElement, render} from "./_snowpack/pkg/@virtualstate/dom.js";
export async function Template({id}, child) {
  let createTemplatePromise;
  return {
    reference: Symbol("Templated Document Node"),
    children: {
      async *[Symbol.asyncIterator]() {
        yield* instance();
      }
    }
  };
  async function* instance() {
    const template = await getTemplate(document);
    yield Array.from(template.content.cloneNode(true).childNodes).map((node, index) => {
      return {
        reference: Symbol(["template", id, index].join(", ")),
        source: "template",
        options: {
          type: "Node",
          instance: node
        }
      };
    });
  }
  async function getTemplate(document2) {
    const existing = document2.querySelector(`template#${id}`);
    if (isHTMLTemplateElement(existing)) {
      return existing;
    }
    return await (createTemplatePromise = createTemplatePromise || createTemplate(document2));
    function isHTMLTemplateElement(value) {
      return isElement(value) && value.tagName.toUpperCase() === "TEMPLATE";
    }
  }
  async function createTemplate(document2) {
    const template = document2.createElement("template");
    const root = document2.createElement("div");
    root.id = "template-root";
    await render(child, root);
    template.content.append(...Array.from(root.childNodes));
    template.id = id;
    document2.body.append(template);
    createTemplatePromise = void 0;
    return template;
  }
}
