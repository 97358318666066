import {h} from "../h.js";
import {Template} from "../template.js";
import {Unbound} from "../unbound.js";
import {Collector, createFragment} from "../_snowpack/pkg/@virtualstate/x.js";
export async function InitialExample() {
  return /* @__PURE__ */ h(createFragment, null, /* @__PURE__ */ h("h3", null, "This is an example of various capabilities of this pattern"), /* @__PURE__ */ h("pre", null, /* @__PURE__ */ h(Loading, null, /* @__PURE__ */ h(AsyncExample, null))));
}
async function AsyncExample() {
  return await new Promise((resolve) => setTimeout(resolve, 1500, `Async result: ${Math.random()}`));
}
async function* Loading(options, child) {
  yield /* @__PURE__ */ h(createFragment, null, "Loading!");
  yield child;
}
export const Example = /* @__PURE__ */ h("section", {
  id: "quick-example"
}, /* @__PURE__ */ h(Template, {
  id: "quick-example-content-template"
}, /* @__PURE__ */ h("h2", null, "A quick example"), /* @__PURE__ */ h("pre", null, `
export async function InitialExample() {
  return (
    <>
      <h3>
        This is an example of various
        capabilities of this pattern
      </h3>
      <pre>
        <Loading>
          <AsyncExample />
        </Loading>
      </pre>
    </>
  )
}

async function AsyncExample() {
  return await new Promise(
    resolve => setTimeout(resolve, 1500, \`Async result: \${Math.random()}\`)
  );
}

async function *Loading(options: unknown, child: VNode) {
  yield <>Loading!</>;
  yield child;
}
        `.trim()), /* @__PURE__ */ h("p", null, "Renders the following output:")), /* @__PURE__ */ h("div", {
  class: "example-output"
}, /* @__PURE__ */ h(Unbound, null, /* @__PURE__ */ h(InitialExample, null))), /* @__PURE__ */ h(Template, {
  id: "bouncing-ball-reacting-example"
}, /* @__PURE__ */ h("h2", null, "Reacting to things"), /* @__PURE__ */ h("pre", null, `
import { Collector } from "@virtualstate/x";
async function *ReactiveExample() {
  const eventCollector = new Collector();
  // Replace the first parameter, don't actually collect any event
  const onClick = eventCollector.add.bind(eventCollector, Symbol("Click"));
  const button = document.createElement("button");
  button.addEventListener("click", onClick);

  let visible = false;

  yield <View />;

  for await (const events of eventCollector) {
    visible = events.reduce((visible: boolean) => !visible, visible);
    yield <View />;
    
    // Re focus the button after the onClick, allows for toggling on and off with keyboard
    button.focus();
  }

  function View() {
    return (
      <div class="ball-container">
        {visible ? <span class="ball" /> : undefined}
        <button type="button" getDocumentNode={() => button}>{visible ? "Grab" : "Bounce"} Ball</button>
      </div>
    )
  }
}
        `.trim()), /* @__PURE__ */ h("p", null, "Renders the following output:")), /* @__PURE__ */ h("div", {
  class: "example-output"
}, /* @__PURE__ */ h(Unbound, null, /* @__PURE__ */ h(ReactiveExample, null))));
async function* ReactiveExample() {
  const eventCollector = new Collector();
  const onClick = eventCollector.add.bind(eventCollector, Symbol("Click"));
  const button = document.createElement("button");
  button.addEventListener("click", onClick);
  let visible = false;
  yield /* @__PURE__ */ h(View, null);
  for await (const events of eventCollector) {
    visible = events.reduce((visible2) => !visible2, visible);
    console.log({events, visible});
    yield /* @__PURE__ */ h(View, null);
    button.focus();
  }
  function View() {
    return /* @__PURE__ */ h("div", {
      class: "ball-container"
    }, visible ? /* @__PURE__ */ h("span", {
      class: "ball"
    }) : void 0, /* @__PURE__ */ h("button", {
      type: "button",
      getDocumentNode: () => button
    }, visible ? "Grab" : "Bounce", " Ball"));
  }
}
