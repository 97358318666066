import {assertElement, render} from "./_snowpack/pkg/@virtualstate/dom.js";
import {h} from "./_snowpack/pkg/@virtualstate/x.js";
export function Unbound(options, child) {
  const bound = Symbol("Bound");
  let rendering;
  return {
    reference: options.reference ?? Symbol("Unbound"),
    source: options.source ?? "div",
    options: {
      type: "Element",
      onBeforeRender(root) {
        assertElement(root);
        rendering = rendering || createRender(root).then(noop);
      }
    }
  };
  async function createRender(root) {
    try {
      root.append(document.createElement("span"));
      await render(/* @__PURE__ */ h(Bound, {
        reference: bound
      }), root);
      options.onComplete?.();
    } catch (error) {
      options.onError?.(error);
    }
  }
  function Bound() {
    return child;
  }
}
function noop() {
  return void 0;
}
